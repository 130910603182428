import { Button } from '@paljs/ui/Button';
import React, { useState, useEffect } from 'react';

const ExportCSV = ({ rows }) => {
    function convertArrayOfObjectsToCSV(village_list) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        console.log('To array:', village_list);
        const keys = Object.keys(rows[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        village_list.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }
    function downloadCSV(village_list) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(village_list);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    return <Button onClick={() => { downloadCSV(rows) }}>Export</Button>;
};
export default ExportCSV;