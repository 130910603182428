import { getApiWrapper, postApiWrapper } from "./token-wrapper-function";

export const getVillageDataFromGoogle = async (village) => {
    const result = await getApiWrapper(`/api/village/${village}`);
    return result;
}

export const postVillageData = async (data, callback) => {
    const result = postApiWrapper(`/api/verfied_village_data`, data);
    return result;
}

export const getDistrictData = async () => {
    const result = await getApiWrapper(`/api/district`);
    return result;
}

export const getTehsilData = async (value) => {
    const result = await getApiWrapper(`/api/${value}/panchayatsamitis`);
    return result;
}

export const getPanchayatData = async (value) => {
    const result = await getApiWrapper(`/api/${value}/grampanchayats`);
    return result;
}

export const getVillageData = async (value) => {
    const result = await getApiWrapper(`/api/${value}/revenue_villages`);
    return result;
 }
