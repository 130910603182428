import { getApiWrapper, postApiWrapper } from "./token-wrapper-function";

export const getWhatsappList = async (data) => {
    let url = `/api/whatsapp_users?start_date=${data?.startDate}&end_date=${data?.endDate}&data_type=${data?.type}`;
    if (data.type == 'LIST_OF_REGISTERED_USERS_WITH_COUNT_CONTRI_IN_VILLAGE_WHERE_COUNT_LT_COUNT') {
        url += `&count_contri=${data?.count_contri}&node_count=${data?.node_count}`;
    }
    else if (data.type == 'LIST_OF_REGISTERED_USERS_IN_VILLAGES_WITH_NODE_COUNT_LT_COUNT_AND_LOGIN_COUNT_LT_COUNT') {
        url += `&node_count=${data?.node_count}&login_count=${data?.login_count}`;
    }
    const result = await getApiWrapper(url);
    return result;
}