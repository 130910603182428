import React, { useState, useRef } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import Select from '@paljs/ui/Select';
import styled from 'styled-components';
import moment from 'moment';
import LoadingSpinner from '../loader'
import { getWhatsappList } from '../../actions/miscellaneousActions';
import { InputGroup } from '@paljs/ui/Input';
import DataTable from 'react-data-table-component';
import { navigate, Link } from 'gatsby';
import DateRangePicker from '../../components/date/date-range'
import differenceBy from 'lodash/differenceBy';
import Modal from '../../components/whatsApp-messages/schedule-message-for-multiple-users';
import VillageListOptions from '../../components/filter/village-list-options'
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import Export from '../../components/export_csv/export';

const SelectStyled = styled(Select)`
  margin-bottom: 1rem;
  width: 40rem;
`;

const statusOption: { value: any; label: any }[] = [
   { label: 'Unregistered Users', value: 'UNREGISTERED_USERS' },
   { label: 'Registered User with zero users added where node count<=100', value: 'Registered User with zero users added where node count<=100' },
   { label: 'Users with add count', value: 'LIST_OF_USERS_WITH_THEIR_ADD_COUNTS' },
   { label: 'Users with registered_by count', value: 'LIST_OF_USERS_WITH_REGISTERED_BY_COUNTS' }
];

const MiscellaneousActions = () => {
   const [list, setList] = useState({});
   const [isOpen, setIsOpen] = useState(false);
   const [ranges, setRanges] = useState([
      {
         startDate: null,
         endDate: null,
      },
   ]);
   const [startDate, setStartDate] = useState('');
   const [asyncData, setAsyncData] = useState({});
   const [endDate, setEndDate] = useState('');
   const [isLoading, setLoader] = useState(false);
   const [showTable, setShowTable] = useState(false);
   const [tableHeader, setTableHeader] = useState('');
   const [rows, setRows] = useState([]);
   const [originalRows, setOriginalRows] = useState([]);
   const [showDate, setShowDate] = useState();
   const [selectedRows, setSelectedRows] = React.useState([]);
   const [toggleCleared, setToggleCleared] = React.useState(false);
   const [isModalOpen, setModalOpen] = useState(false);
   const [title, setTitle] = useState('')
   const [toastData, setToastData] = useState<ToastrProps>({
      position: 'topEnd',
      status: 'Success',
      duration: 2000,
      hasIcon: true,
      destroyByClick: true,
      preventDuplicates: false,
   });
   const toastrRef = useRef<ToastrRef>(null);
   const villageListRef = useRef()

   const requestedWhatsappList = async (data) => {
      setLoader(true);
      const response = await getWhatsappList(data);
      console.log("whatsapp list", response);
      let result = {};
      if (list.value == 'UNREGISTERED_USERS') {
         result = response?.results.user_list.map((item, index) => {
            return { ...item, seq: index + 1 }
         });
      }
      else if (list.value == 'Registered User with zero users added where node count<=100') {
         result = response?.results.user_list.map((item) => {
            return {
               name: item['registered_user_details']?.name,
               gautra: item['registered_user_details']?.gautra,
               village: item['registered_user_details']?.village,
               mobile: item['registered_user_details']?.registered_mobile,
               count: item.count,
               block: item.village_block,
               district: item.village_district
            }
         });
      }
      else if (list.value == 'LIST_OF_USERS_WITH_THEIR_ADD_COUNTS' || list.value == 'LIST_OF_USERS_WITH_REGISTERED_BY_COUNTS') {
         result = response?.results.user_list.map((item) => {
            return {
               name: item['users']?.name,
               gautra: item['users']?.gautra,
               village: item['users']?.village,
               mobile: item['users']?.registered_mobile,
               count: (item.hasOwnProperty('added_nodes_count') ? item.added_nodes_count : item.registered_nodes_count),
               block: item.village_block,
               district: item.village_district
            }
         });
      }
      setRows(result);
      setOriginalRows(result);
      setTableHeader(list.label);
      setTitle(data.type)
      setShowTable(true);
      setLoader(false);
      setShowDate({ ...data })

   }
   const tableColumns = [
      {
         name: 'S.No.',
         sortable: false,
         grow: 0.2,
         cell: (row, index, column, id) => {
            return (<div>{index + 1}</div>)
         }
      },
      {
         name: 'Name',
         selector: (row) => row.name,
         sortable: true
      },
      {
         name: 'Gautra',
         selector: (row) => row.gautra,
         sortable: true,
      },
      {
         name: 'Village',
         selector: (row) => row.village,
         sortable: true,
      },
      {
         name: 'Mobile No.',
         selector: (row) => row.mobile,
         sortable: false,
      },
      {
         name: 'District',
         selector: (row) => row.district,
         sortable: false,
      },
      {
         name: 'Block',
         selector: (row) => row.block,
         sortable: false,
      },
      {
         name: 'Count',
         selector: (row) => row.count,
         sortable: true,
         omit: (list.value == 'UNREGISTERED_USERS' ? true : false),
      },
      {
         name: 'Actions',
         grow: 1,
         left: true,
         cell: (row) =>
            <>
               <a href={`/whatsapp/templates?node_id=${row.uid}`} target='_blank'>
                  <Button
                     shape={'Round'}
                     size={'Tiny'}
                     Status={'Success'}
                  >
                     Whatsapp Message
                  </Button>
               </a>
            </>
      },
   ];
   const onFilter = () => {
      filterAction({ ...asyncData })
   }

   const onReset = () => {
      setStartDate('');
      setEndDate('')
      setAsyncData({});
      filterAction({
         district: '',
         tehsil: '',
         panchayat: '',
         village: ''
      })
      villageListRef.current.clearData();
   }
   const filterAction = async (data) => {
      console.log("data to be filtered", data);
      const filteredByDistrict = originalRows.filter((item) => {
         if (data?.district) {
            return item.district == data.district
         } else {
            return item
         }
      })
      console.log("filtere data of district", filteredByDistrict);
      const filteredByTehsil = filteredByDistrict.filter((item) => {
         if (data?.tehsil) {
            return item.tehsil == data.tehsil
         } else {
            return item
         }
      }
      )
      console.log("filtere data of tehsil", filteredByTehsil);
      const filterByPanchayat = filteredByTehsil.filter((item) => {
         if (data?.panchayat) {
            return item.panchayat == data.panchayat
         } else {
            return item
         }
      })
      console.log("filtere data of panchayat", filterByPanchayat);
      const filterByVillage = filterByPanchayat.filter((item) => {
         if (data?.village) {
            return item.village == data.village
         } else {
            return item
         }
      })
      console.log("filtere data", filterByVillage);

      setRows(filterByVillage);

   }
   const asyncChanges = (data) => {
      setAsyncData(data);
   }

   const onDateRangeToggle = () => {
      setIsOpen(!isOpen);
   };

   const do_search = (value) => {
      const serachValue = value.trim()
      const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(serachValue) >= 0);
      setRows(temp_rows);
   };

   const handleInputChange = (event) => {
      do_search(event.target.value)
   };

   const onSubmit = async () => {
      let urlArguments = {};
      if (list.value == 'UNREGISTERED_USERS' || list.value == 'LIST_OF_USERS_WITH_THEIR_ADD_COUNTS' || list.value == 'LIST_OF_USERS_WITH_REGISTERED_BY_COUNTS') {
         urlArguments = {
            type: list.value,
            startDate,
            endDate
         }
      }
      else if (list.value == 'Registered User with zero users added where node count<=100') {
         urlArguments = {
            type: 'LIST_OF_REGISTERED_USERS_WITH_COUNT_CONTRI_IN_VILLAGE_WHERE_COUNT_LT_COUNT',
            startDate,
            endDate,
            count_contri: 1,
            node_count: 100
         }
      }
      requestedWhatsappList(urlArguments)
   }

   const dateRangeChange = (range) => {
      const changedStartDate = moment(range.startDate).format("YYYY-MM-DD");
      const changedEndDate = moment(range.endDate).format("YYYY-MM-DD");
      range.startDate !== range.endDate ? setIsOpen(!isOpen) : '';
      setStartDate(changedStartDate);
      setEndDate(changedEndDate);
   }

   const handleRowSelected = React.useCallback(state => {
      console.log('state for rows selected', state)
      setSelectedRows(state.selectedRows);
   }, []);

   const contextActions = React.useMemo(() => {

      return (
         < Button onClick={() => { setModalOpen(true) }} >
            Schedule WhatsApp Message
         </Button >
      );

   }, [rows, selectedRows]);

   const actionsMemo = React.useMemo(() => <Export rows={rows} />, [rows]);

   return (
      <>
         <Toastr ref={toastrRef} />
         {isModalOpen ? (<Modal selectedRows={selectedRows} onClose={() => { setModalOpen(false) }}
            //whatsApp messages schedule
            onSubmit={(data) => {
               if (data) {
                  const toastTitle = 'Success';
                  const toastMessage = 'Mesaage Scheduled sucessfully';
                  toastrRef.current?.add(toastMessage, toastTitle, { ...toastData });
               }
               else {
                  const toastTitle = 'Error';
                  const toastMessage = 'Error Occurred';
                  toastrRef.current?.add(toastMessage, toastTitle, { ...toastData, status: 'Danger' });
               }
            }}
         />) : ''}
         <Card status="Info">
            <CardHeader>Miscellaneous Actions</CardHeader>
            <CardBody>
               <Row>
                  <Col >
                     <SelectStyled options={statusOption} placeholder="Status" onChange={(value) => { setList(value) }} value={list} />
                  </Col>
                  <br />
                  <Col breakPoint={{ xs: 12, md: 4 }}>
                     <Button
                        fullWidth
                        appearance="outline"
                        shape={'Round'}
                        size={'Small'}
                        status={'Info'}
                        onClick={onDateRangeToggle}
                     >
                        Select Date Range
                     </Button>

                     <DateRangePicker
                        onChange={dateRangeChange}
                        isOpen={isOpen}
                     />

                  </Col>
                  <br />
               </Row>
            </CardBody>
            <CardFooter>
               <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button status="Success" type="button" shape="SemiRound"
                     style={{ margin: '10px', display: 'block' }}
                     onClick={onSubmit}
                  >
                     submit
                  </Button>
               </Row>
            </CardFooter>
         </Card>
         <br />
         <Card status="Info">
            <CardHeader>Filter</CardHeader>
            <CardBody>
               <Row>
                  <Col>
                     <VillageListOptions onChange={asyncChanges} ref={villageListRef} type='creatable' />
                  </Col>
               </Row>
            </CardBody>
            <CardFooter>
               <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button status="Success" type="button" shape="SemiRound"
                     style={{ margin: '10px', display: 'block' }}
                     onClick={onFilter}
                  >
                     Filter
                  </Button>
                  <Button status="Danger" type="button" shape="SemiRound"
                     style={{ margin: '10px', display: 'block' }}
                     onClick={onReset}
                  >
                     Reset
                  </Button>
               </Row>
            </CardFooter>
         </Card>
         {
            isLoading ? (<LoadingSpinner message={`Loading Data for ${list.label} from ${startDate} to ${endDate} `} />) :
               showTable ? (
                  <>
                     <Col> <h1 className='text-heading'>{showDate ? (startDate ? `Results from ${showDate.startDate} to ${showDate.endDate}` : '') : ''}</h1></Col>
                     <div style={{ textAlign: 'center', backgroundColor: 'whitesmoke', color: 'black', padding: "18px", fontSize: "25px" }}>{tableHeader}</div>
                     <InputGroup fullWidth size="Small" status="Info">
                        <input
                           type="text"
                           placeholder="Search..."
                           onChange={(event) => handleInputChange(event)}
                        />
                     </InputGroup>
                     <DataTable
                        title={title}
                        columns={tableColumns}
                        data={rows}
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                        paginationPerPage={100}
                        striped
                        selectableRows
                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                        clearSelectedRows={toggleCleared}
                        dense
                        highlightOnHover
                        actions={actionsMemo}
                     />

                  </>
               ) : ('')
         }
      </>
   )
}

export default MiscellaneousActions;
