import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import Select from '../../pages/villages/select-options';
import { getDistrictData, getTehsilData, getPanchayatData, getVillageData } from '../../actions/villageVerifyAction';
import zIndex from '@material-ui/core/styles/zIndex';


const AsyncOptions = forwardRef(({ onChange, type = null, defaultValues = {} }, ref) => {
    const [districtOption, setDistrictOption] = useState([]);
    const [tehsilOption, setTehsilOption] = useState([]);
    const [panchayatOption, setPanchayatOption] = useState([]);
    const [villageOption, setVillageOption] = useState([]);
    const [panchayat, setPanchayat] = useState(defaultValues?.panchayat);
    const [tehsil, setTehsil] = useState(defaultValues?.tehsil);
    const [district, setDistrict] = useState(defaultValues?.district);
    const [village, setVillage] = useState(defaultValues?.village);

    useEffect(() => {
        try {
            districtData();
            onDistrictSelect(defaultValues?.district);
            onTehsilSelect(defaultValues?.tehsil);
            onPanchayatSelect(defaultValues?.panchayat);
            setVillage(defaultValues?.village);

        } catch (error) {
            console.log('Something went wrong!', error);
        }
    }, []);

    const filterFields = {
        district,
        tehsil,
        panchayat,
        village
    }

    useImperativeHandle(ref, () => ({
        clearData() {
            setVillage('');
            setPanchayat('');
            setTehsil('');
            setDistrict('');
            setTehsilOption([]);
            setPanchayatOption([]);
            setVillageOption([]);
        },
    }))

    const districtData = async () => {
        const response = await getDistrictData()
        console.log("from async function getting districts", response);
        const data = response.data.district.map((val) => {
            return {
                value: val,
                label: val,
            }
        })
        setDistrictOption(data);
    }

    const onDistrictSelect = async (v) => {
        const value = v?.trim()
        console.log("from district select to check", value);
        setPanchayat("");
        setTehsil("");
        setVillage("");
        setVillageOption([]);
        setPanchayatOption([]);
        const response = await getTehsilData(value)
        console.log("from district select", response)
        const data = response.data.panchayat_samiti.map((val) => {
            return {
                value: val,
                label: val,
            }
        })
        setTehsilOption(data)
        setDistrict(value)
        onChange({ ...filterFields, district: value })
    }

    const onTehsilSelect = async (v) => {
        const value = v?.trim()
        setPanchayat("");
        setVillage("");
        setVillageOption([]);
        setPanchayatOption([]);
        const response = await getPanchayatData(value)
        console.log("the data of Tehsils from apis to verify village", response)
        const data = response.data.gram_panchayats.map((val) => {
            return {
                value: val,
                label: val,
            }
        })
        setPanchayatOption(data)
        setTehsil(value)
        onChange({ ...filterFields, tehsil: value })
    }

    const onPanchayatSelect = async (v) => {
        const value = v?.trim()
        setVillage("");
        setVillageOption([]);
        const response = await getVillageData(value)
        console.log("the data of Tehsils from apis to verify village", response)
        const data = response.data.village_list.map((val) => {
            return {
                value: val,
                label: val,
            }
        })
        setVillageOption(data)
        setPanchayat(value)
        onChange({ ...filterFields, panchayat: value })
    }


    return (
        <>
            <Row style={{ display: "flex" }}>
                <Col style={{ width: '250px', zIndex: '10' }}>
                    <Select
                        options={districtOption}
                        onSelect={onDistrictSelect}
                        value={district}
                        name='district'
                        type={type}
                    />
                </Col>

                <Col style={{ width: '250px' }}>
                    <Select
                        options={tehsilOption}
                        onSelect={onTehsilSelect}
                        value={tehsil}
                        name='tehsil'
                        type={type}
                    />
                </Col>

                <Col style={{ width: '250px' }}>
                    <Select
                        options={panchayatOption}
                        onSelect={onPanchayatSelect}
                        value={panchayat}
                        name='panchayat'
                        type={type}
                    />
                </Col>

                <Col style={{ width: '250px' }}>
                    <Select
                        options={villageOption}
                        onSelect={(v) => {
                            const value = v?.trim()
                            onChange({ ...filterFields, village: value })
                            setVillage(value)
                        }}
                        value={village}
                        name='village'
                        type={type}
                    />
                </Col>
            </Row>
        </>
    )
})
export default AsyncOptions;
